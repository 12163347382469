
import {defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {useStore} from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "forgot-password",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);

    // Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string()
        .email()
        .required()
        .label("Email")
    });

    // Form submit function
    const onSubmitForgotPassword = values => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.FORGOT_PASSWORD, values)
        .then(response => {
          let text = "Whoops, something went wrong";
          let icon = "error";
          let action = () => {};

          if (response.data.status === 'passwords.throttled') {
            text = "Too many requests, please try again later";
            icon = "warning";
          } else if (response.data.status === 'passwords.sent') {
            text = "Reset password email was successfully sent";
            icon = "success";
            action = () => router.push({ name: "sign-in" });
          }

          Swal.fire({
            text,
            icon,
            buttonsStyling: false,
            confirmButtonText: "Ok, got it",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(action);
        })
        .catch(() => {
          // Alert then login failed
          Swal.fire({
            text: 'Failed to send reset password email',
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  }
});
